import React from "react";
import ReactPlayer from "react-player";
import "./VideoPlayer.scss";
const VideoPlayer = ({ videoURL, videoReady, autoPlay = false }) => {
  const playerVars = autoPlay
    ? {
        controls: 0,
        autoplay: 1,
        loop: 1,
        disablekb: 1,
        modestbranding: 1,
        showinfo: 0,
      }
    : {};

  return (
    <div className={`video-player ${autoPlay ? "autoplay" : ""}`}>
      <ReactPlayer
        playing={autoPlay}
        muted={autoPlay}
        playsinline={autoPlay}
        loop={autoPlay}
        config={{
          youtube: {
            playerVars: playerVars,
          },
        }}
        onReady={() => {
          if (videoReady) {
            videoReady();
          }
        }}
        width="100%"
        height="100%"
        url={videoURL}
      />
    </div>
  );
};
export default VideoPlayer;
